import { ethers } from "ethers";
import { action, makeObservable, observable } from "mobx";
import Web3Modal from "web3modal";
import configData from "../constants/config-data.json"
import NotificationTypeEnum from "../enums/notification-type-enum";

export class EtherStore {
    walletAddress = "";
    walletBalance = 0;
    chainId = 0;
    nftBalance = [];
    signer = {};
    sliderValue = 1;
    soldNfts = 0;
    maxNFTendos = 10000;
    currentPrice = 0;
    address = configData.address;
    PROJECT_ID = configData.projectId;
    defaultProvider = new ethers.getDefaultProvider(configData.defaultProvider);
    abi = [
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "numNFTendos",
                    "type": "uint256"
                }
            ],
            "name": "adoptNFTendo",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "calculatePrice",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_id",
                    "type": "uint256"
                }
            ],
            "name": "calculatePriceForToken",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "MAX_NFTENDOS",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "totalSupply",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_owner",
                    "type": "address"
                }
            ],
            "name": "tokensOfOwner",
            "outputs": [
                {
                    "internalType": "uint256[]",
                    "name": "", "type": "uint256[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
          {
            "inputs": [
              {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
              }
            ],
            "name": "ownerOf",
            "outputs": [
              {
                "internalType": "address",
                "name": "",
                "type": "address"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "from",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "to",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
              }
            ],
            "name": "transferFrom",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "uint256",
                "name": "slot",
                "type": "uint256"
              }
            ],
            "name": "winnerWithdraw",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
              }
            ],
            "name": "winningNumbers",
            "outputs": [
              {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          }
    ];
    defaultContract = new ethers.Contract(this.address, this.abi, this.defaultProvider);
    uiStoreRef = null;

    constructor(rootStore) {
        makeObservable(this, {
            //observable state
            walletAddress: observable,
            signer: observable,
            sliderValue: observable,
            walletBalance: observable,
            nftBalance: observable,
            soldNfts: observable,
            maxNFTendos: observable,
            currentPrice: observable,
            chainId: observable,

            //actions
            setWalletAddress: action,
            setWalletBalance: action,
            setNftBalance: action,
            connectToWallet: action,
            purchaseNft: action,
            setSigner: action,
            setSliderValue: action,
            setSoldNfts: action,
            setMaxNFTendos: action,
            setCurrentPrice: action,
            setChainId: action,
        });
        this.uiStoreRef = rootStore.uiStore;
        this.getTotalSold();
        this.getCurrentPrice();
    }

    getTotalSold = async () => {
        try {
            const amountSold = await this.defaultContract.totalSupply();
            this.setSoldNfts(String(amountSold));
        } catch (e) {
            console.log(e);
        }
    };


    getNftBalance = async () => {
        try {
            const nftsOwned = await this.defaultContract.tokensOfOwner(this.walletAddress);
            this.setNftBalance(nftsOwned);
        } catch (e) {
            console.log(e);
        }
    };

    getCurrentPrice = async () => {
        try {
            const price = await this.defaultContract.calculatePrice();
            this.setCurrentPrice(String(price));
        } catch (e) {
            console.log(e);
        }
    };

    connectToWallet = async () => {
        let provider;

        const providerOptions = {
        };

        const web3Modal = new Web3Modal({
            cacheProvider: false, // optional
            providerOptions // required
        });
        try {
            const connector = await web3Modal.connect();
            provider = new ethers.providers.Web3Provider(connector);
            this.setSigner(provider.getSigner());
            this.setWalletAddress(provider.provider.selectedAddress);
            const balance = await this.defaultProvider.getBalance(this.walletAddress);
            this.setWalletBalance(ethers.utils.formatEther(balance));
            this.getNftBalance();
            this.setChainId(parseInt(provider.network.chainId));
            if (parseInt(provider.network.chainId) !== configData.chainId) {
                this.uiStoreRef.isCorrectWallet = false;
                this.showNotification(
                    !this.uiStoreRef.notificationBar.isVisible,
                    "Wrong Chain ID, connect to Binance Smart Chain Mainnet!",
                    NotificationTypeEnum.ERROR
                )
            } else {
                this.uiStoreRef.isCorrectWallet = true;
            }

            connector.on("accountsChanged", (accounts: string[]) => {
                this.setWalletBalance(ethers.utils.formatEther(balance));
            });

            // Subscribe to chainId change
            connector.on("chainChanged", (chainId: number) => {
                this.setChainId(parseInt(chainId));
                if (parseInt(chainId) !== configData.chainId) {
                    this.showNotification(
                        !this.uiStoreRef.notificationBar.isVisible,
                        "Wrong Chain ID, connect to Binance Smart Chain Testnet!",
                        NotificationTypeEnum.ERROR
                    )
                }
            });

            // Subscribe to provider connection
            connector.on("connect", (info: { chainId: number }) => {
                console.log(info);
            });

            // Subscribe to provider disconnection
            connector.on("disconnect", (error: { code: number; message: string }) => {
                console.log(error);
            });

        } catch (e) {
            console.log(e);
        }
    };

    showNotification = (condition, notificationMessage, notificationType) => {
        if (this.uiStoreRef) {
            if (condition) {
                this.uiStoreRef.setNotificationMessage(
                    notificationMessage,
                    notificationType
                )
            } else {
                const resetValue = notificationType === NotificationTypeEnum.ERROR
                this.uiStoreRef.setNotificationBarVisibility(resetValue)
                this.uiStoreRef.setNotificationMessage(
                    notificationMessage,
                    notificationType
                )
            }
        }
    }

    setSigner = (value) => {
        this.signer = value;
    }

    setWalletAddress = (value) => {
        this.walletAddress = value;
        this.showNotification(
            !this.uiStoreRef.notificationBar.isVisible,
            "Successfully connected to wallet: ..." + value.slice(-8),
            NotificationTypeEnum.SUCCESS
        )
    }

    setWalletBalance = (value) => {
        this.walletBalance = value;
    }

    setChainId = (value) => {
        this.chainId = value;
    }

    setNftBalance = (value) => {
        this.nftBalance = value;
    }

    setCurrentPrice = (value) => {
        this.currentPrice = value;
    }

    setSoldNfts = (value) => {
        this.soldNfts = value;
    }

    setMaxNFTendos = (value) => {
        this.maxNFTendos = value;
    }

    purchaseNft = async () => {
        const contract = new ethers.Contract(this.address, this.abi, this.signer);
        const purchasePrice = this.sliderValue * this.currentPrice;
        let totalNftPrice = ethers.utils.parseUnits(String(purchasePrice), "wei");
        let successfulPurchase = false;
        try {
            let tx = await contract.adoptNFTendo(this.sliderValue, { value: totalNftPrice });
            await tx.wait();
            successfulPurchase = true;
            this.getTotalSold();
            this.getCurrentPrice();
            const balance = await this.defaultProvider.getBalance(this.walletAddress);
            this.setWalletBalance(ethers.utils.formatEther(balance));
        } catch (e) {
            console.log('Transaction failed');
            console.log(e);
        }
        if (successfulPurchase) {
            this.showNotification(
                !this.uiStoreRef.notificationBar.isVisible,
                "Successfully purchased NFTendo!",
                NotificationTypeEnum.SUCCESS
            )
        }
        else {
            this.showNotification(
                !this.uiStoreRef.notificationBar.isVisible,
                "Failed to purchased NFTendo!",
                NotificationTypeEnum.ERROR
            )
        }
    };

    setSliderValue = (value) => {
        this.sliderValue = value;
    }
}

export default EtherStore;
