import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationTypeEnum from "../../enums/notification-type-enum";
import firebase from "../../firebase";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Modal = observer(() => {
    const { t } = useTranslation();
    const { uiStore } = useStores();
    const { modalOpen, setModalOpen, setNotificationMessage } = uiStore;
    const [proposal, setProposal] = useState(
        {
            email: "",
            title: "",
            name: "",
            message: "",
        }
    );

    const handleChange = (evt, field) => {
        setProposal((prevState) => ({ ...prevState, [field]: evt.target.value }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    }


    const saveToDb = () => {
        firebase
            .firestore()
            .collection("proposals")
            .add(proposal)
            .then(() => {
                setNotificationMessage(
                    "Successfully sent your proposal",
                    NotificationTypeEnum.SUCCESS
                )
            })
            .catch((error) => {
                setNotificationMessage(
                    "Something went wrong",
                    NotificationTypeEnum.ERROR
                )
            });
    };

    return (
        modalOpen &&
        <div>
            <div className={styles["modal_backdrop"]} onClick={() => setModalOpen(false)} />
            <div className={styles["modal_container"]}>
                <div className={styles["modal_container-x"]} onClick={() => setModalOpen(false)}>
                    ✖
                </div>
                <form className={styles["modal_form"]} onSubmit={handleSubmit}>
                    <h2>Proposal</h2>
                    <label className={styles["modal_label"]}>
                        <span className={styles["modal_label-title"]}>
                            E-mail:
                        </span>
                        <input required type="email" placeholder="Enter your email" value={proposal.email} onChange={(event) => handleChange(event, "email")} className={styles["modal_input"]} type="text" name="name" />
                    </label>
                    <label className={styles["modal_label"]}>
                        <span className={styles["modal_label-title"]}>
                            Title:
                        </span>
                        <input required placeholder="Title of your proposal" value={proposal.title} onChange={(event) => handleChange(event, "title")} className={styles["modal_input"]} type="text" name="name" />
                    </label>
                    <label className={styles["modal_label"]}>
                        <span className={styles["modal_label-title"]}>
                            Name:
                        </span>
                        <input required placeholder="Enter your Name" namevalue={proposal.name} onChange={(event) => handleChange(event, "name")} className={styles["modal_input"]} type="text" name="name" />
                    </label>
                    <label className={styles["modal_label"]}>
                        <span className={styles["modal_label-title"]}>
                            Message:
                        </span>
                        <textarea required placeholder="Write your proposal here..." value={proposal.message} onChange={(event) => handleChange(event, "message")} className={styles["modal_textarea"]} />
                    </label>
                    <input type="submit" value="Submit" className={styles["modal_button"]} onClick={() => { saveToDb(); setModalOpen(false) }} />
                </form>
            </div>
        </div>
    )
});

export default Modal;