import { observer } from "mobx-react";
import React, { useEffect } from 'react';
import AboutFdm from "../../components/about-fdm";
import AboutKites from "../../components/about-kites";
import Header from "../../components/header";
import Partners from "../../components/partners";
import PurchaseNfts from "../../components/purchase-nfts";
import Roadmap from "../../components/roadmap";
import Team from "../../components/team";
import WelcomeToFdm from "../../components/welcome-to-fdm";
import { useStores } from "../../stores/RootStore";
import Proposals from "../proposals";

const Home = observer(() => {
    const { uiStore } = useStores();
    const { setHomeMounted } = uiStore;

    useEffect(() => {
        setHomeMounted(true);
        return () => { setHomeMounted(false); };
    });

    return (
        <>
            <Header />
            <WelcomeToFdm />
            <AboutFdm />
            <AboutKites />
            <PurchaseNfts />
            <Roadmap />
            {
                //<Team />
            }
            <Partners />
        </>
    )
});

export default Home;