import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const Team = observer(() => {
    const { t } = useTranslation();
    const teamArray = t("team", { returnObjects: true });
    /*
     {faqArray.map((item, i) => {
                        return (
                            <div key={i}>
                                <div className={styles["faq-title"]} onClick={() => faqHandler(i)}>
                                    {item.question}
                                </div>
                                {faqsOpen.includes(i) &&
                                    <div className={styles["faq-description"]}>
                                        {item.answer}
                                    </div>}
                            </div>
                        )
                    })}
    */
    return (

        <div id="team" data-aos="zoom-in" className={styles["container"]}>
            <h2>
                Team
            </h2>
            <div className={styles["team_container"]}>
                {teamArray.sort((a, b) => 0.5 - Math.random()).map((item, i) => {
                    return <div className={styles["team_card"]} >
                        <img className={styles["team_image"]} src={item.image} alt="Peter profile" />
                        <div className={styles["team_text"]}>
                            <span className={styles["team_text-name"]}>{item.name}</span>
                            <div className={styles["team_text-wrapper"]}>
                                <span className={styles["team_text-title"]}>"{item.quote}"</span>
                                <span className={styles["team_text-description"]}>{item.description}</span>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div >
    )
});

export default Team;
