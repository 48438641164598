import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-scroll";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const NavigationBar = observer(() => {
    const { t } = useTranslation();
    const { uiStore } = useStores();
    const { hamburgerMenuOpen, switchHamburgerMenu, setModalOpen } = uiStore;
    const [scrollDirUp, setScrollDirUp] = useState(true);

    useEffect(() => {
        const threshold = 50;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDirUp(scrollY > lastScrollY ? false : true);
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollDirUp]);


    return (
        <div className={styles["navbar-container"]}>
            <div className={styles["container"]}>
                <div className={styles["navbar-container_background"]}>
                    <div className={styles["navbar-logo-wrapper"]}>
                        <div className={styles["navbar-logo-container"]}>
                            <img className={styles["navbar-logo-container_logo"]} src={"images/fundamint-logo.svg"} alt="Fundamint Logo" />
                            <img className={styles["navbar-logo-container_text"]} src={"images/fundamint-text.svg"} alt="Fundamint Text" />
                        </div>
                    </div>
                    <div className={styles["navbar-links-container"]}>
                        <Link activeClass={styles["active-link"]} to="header">{t("navbar.home")}</Link >
                        <Link activeClass={styles["active-link"]} to="about">{t("navbar.about")}</Link>
                        <Link activeClass={styles["active-link"]} to="purchase">{t("navbar.fdm")}</Link>
                        <Link activeClass={styles["active-link"]} to="roadmap">{t("navbar.roadmap")}</Link>
                        {
                            //<Link activeClass={styles["active-link"]} to="team">{t("navbar.team")}</Link>
                        }
                        <Link activeClass={styles["active-link"]} to="partners">{t("navbar.partners")}</Link>
                        <Link onClick={() => setModalOpen(true)}>{t("navbar.proposal")}</Link>
                    </div>
                    <div className={styles["hamburger-menu"]} onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); }}>
                        <img className={styles["hamburger-menu_icon"]} src={"images/hamburger.svg"} alt="Hamburger Menu" />
                    </div>
                </div>
                {scrollDirUp ? <div className={styles["navbar_socials"]}>
                    <a href="https://medium.com/@fundamintnft" target="_blank">
                        <img className={styles["navbar_socials-icon"]} src={"images/medium.png"} alt="Medium Icon" />
                    </a>
                    <a href="https://discord.gg/4kMarRDbAd" target="_blank">
                        <img className={styles["navbar_socials-icon"]} src={"images/discord.png"} alt="Discord Icon" />
                    </a>
                    <a href="https://twitter.com/FundamintNFT" target="_blank">
                        <img className={styles["navbar_socials-icon"]} src={"images/twitter.png"} alt="Twitter Icon" />
                    </a>
                </div>
                    :
                    null
                }
            </div>
            {hamburgerMenuOpen ?
                <div className={styles["hamburger-menu-dropdown"]}>
                    <Link onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen) }} activeClass={styles["active-link"]} to="header">{t("navbar.home")}</Link >
                    <Link onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen) }} activeClass={styles["active-link"]} to="about">{t("navbar.about")}</Link>
                    <Link onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen) }} activeClass={styles["active-link"]} to="purchase">{t("navbar.fdm")}</Link>
                    <Link onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen) }} activeClass={styles["active-link"]} to="roadmap">{t("navbar.roadmap")}</Link>
                    {
                        //<Link onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen) }} activeClass={styles["active-link"]} to="team">{t("navbar.team")}</Link>
                    }
                    <Link onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen) }} activeClass={styles["active-link"]} to="partners">{t("navbar.partners")}</Link>
                    <Link onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setModalOpen(true) }}>{t("navbar.proposal")}</Link>
                </div>
                : null}
        </div>
    )
});

export default NavigationBar;
