import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAYu809-g2AxiSoRkAw-DdEY9_uIBPs_VU",
    authDomain: "fundamint-6131f.firebaseapp.com",
    projectId: "fundamint-6131f",
    storageBucket: "fundamint-6131f.appspot.com",
    messagingSenderId: "85099450986",
    appId: "1:85099450986:web:6226a76b1ab5efb0f3f013"
};

firebase.initializeApp(firebaseConfig)
// firebase.analytics();
export default firebase
