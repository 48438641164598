import { observer } from "mobx-react";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import Button from "../button";
import styles from "./index.module.scss";

const Header = observer(() => {

    const { t } = useTranslation();
    const [hoveredCard, setHoveredCard] = useState(0);
    
    return (
        <div id="header" className={styles["container"]}>
            <div className={styles["header-container"]}>
                <div className={styles["header-container_title-block"]}>
                    <div data-aos="zoom-in">
                        {t("header.title1")} <span className={styles["header-container_title-block-bold"]}>{t("header.title2")}</span>
                    </div>
                    <div data-aos="zoom-in" className={styles["header-container_details"]}>
                        <div className={styles["header-container_description-block"]}>
                            <span className={styles["header-container_description-block-title-1"]} >
                                0
                            </span>
                            <span className={styles["header-container_description-block-text"]}>
                                {t("header.sold")}
                            </span>
                        </div>
                        <div className={styles["header-container_description-block"]}>
                            <span className={styles["header-container_description-block-title-2"]}>
                                $0
                            </span>
                            <span className={styles["header-container_description-block-text"]}>
                                {t("header.funded")}
                            </span>
                        </div>
                    </div>
                </div>
                <div data-aos="zoom-in" className={styles["header-container_images"]}>
                    <img onMouseEnter={() => setHoveredCard(1)} onMouseOut={() => setHoveredCard(0)} className={styles["header-container_kite"]} style={(hoveredCard === 2 || hoveredCard === 3) ? { transform: `scale(0.8) rotate(-25deg) translateX(-50px)`, opacity: 0.8 } : { width: "" }} src={"images/kite_green.png"} alt="Fundamint Rare Card" />
                    <img onMouseEnter={() => setHoveredCard(2)} onMouseOut={() => setHoveredCard(0)} className={styles["header-container_kite"]} style={hoveredCard === 1 ? { transform: `scale(0.8) rotate(-5deg) translateX(50px)`, opacity: 0.8 } : hoveredCard === 3 ? { transform: `scale(0.8) rotate(-5deg) translateX(-50px)`, opacity: 0.8 } : { width: "" }} src={"images/kite_purple.png"} alt="Fundamint Epic Card" />
                    <img onMouseEnter={() => setHoveredCard(3)} onMouseOut={() => setHoveredCard(0)} className={styles["header-container_kite"]} style={(hoveredCard === 1 || hoveredCard === 2) ? { transform: `scale(0.8) rotate(15deg) translateX(50px)`, opacity: 0.8 } : { width: "" }} src={"images/kite_orange.png"} alt="Fundamint Legendary Card" />
                </div>
                <div data-aos="zoom-in" className={styles["header-container_buttons-block"]}>
                    <span className={styles["header-container_buttons-block-title"]}>
                        {t("header.collection")}
                    </span>
                    <span className={styles["header-container_buttons-block-description"]}>
                        {t("header.kites")}
                    </span>
                    <Link activeClass={styles["active-link"]} to="about-kites" spy={true}>
                        <Button label={t("header.about")} />
                    </Link>
                    <Link activeClass={styles["active-link"]} to="purchase" spy={true}>
                        <Button label={t("header.buy")} />
                    </Link>
                </div>
            </div>
        </div>
    )
});

export default Header;
