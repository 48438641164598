import { observer } from "mobx-react";
import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import coreStyles from 'react-awesome-slider/src/core/styles.scss';
import animationStyles from 'react-awesome-slider/src/styled/cube-animation/cube-animation.scss';
import styles from "./index.module.scss";
import withAutoplay from 'react-awesome-slider/dist/autoplay';

const AwsomeSliderFdm = observer(() => {
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    return (
        <AutoplaySlider className={styles["slider"]}
            mobileTouch={false}
            animation="cubeAnimation"
            cssModule={[coreStyles, animationStyles, styles]}
            play={true}
            cancelOnInteraction={true}
            interval={6000}
        >
            <div data-src="images/kite_green.png" />
            <div data-src="images/kite_blue.png" />
            <div data-src="images/kite_purple.png" />
            <div data-src="images/kite_orange.png" />
        </AutoplaySlider>
    )
});

export default AwsomeSliderFdm;
