const NavbarEnum = Object.freeze({
    HOME: "Home",
    MISSION: "Mission",
    CURRENT_CAUSE: "Current Cause Collection",
    FDM: "Buy FDM NFT",
    ROADMAP: "Roadmap",
    TEAM: "Team",
    PARTNERS: "Partners",
    PROPOSAL: "Proposal"
})

export default NavbarEnum;