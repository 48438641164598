import { observer } from "mobx-react";
import React from 'react';
import styles from "./index.module.scss";

const Roadmap = observer(() => {

    return (

        <div id="roadmap" data-aos="zoom-in" className={styles["container"]}>
            <h2>
                Roadmap
            </h2>
            <div className={styles["roadmap_wrapper"]}>
                <div className={styles["roadmap_block"]}>
                    <img className={styles["roadmap_logo-on"]} alt={"Fundamint Logo Opened"} src={"images/fundamint-logo.svg"} />
                    <div className={styles["roadmap_title"]}>
                        FUND token Distribution
                    </div>
                    <div className={styles["roadmap_description"]}>
                        Distribute fungible FUND tokens to genesis KITE of Freedom NFT holders. Fund tokens will serve as a voting mechanism in our DAO for further series.
                    </div>
                </div>
                <div className={styles["roadmap_block"]}>
                    <img className={styles["roadmap_logo-off"]} alt={"Fundamint Logo Closed"} src={"images/fundamint-logo.svg"} />
                    <div className={styles["roadmap_title"]}>
                        Networking
                    </div>
                    <div className={styles["roadmap_description"]}>
                        Partner up and collaborate with various organizations to broaden the network and influence range.
                    </div>
                </div>
                <div className={styles["roadmap_block"]}>
                    <img className={styles["roadmap_logo-off"]} alt={"Fundamint Logo Closed"} src={"images/fundamint-logo.svg"} />
                    <div className={styles["roadmap_title"]}>
                        DAO
                    </div>
                    <div className={styles["roadmap_description"]}>
                        Set up the DAO and begin voting decisions on the choice of further series proceeds. (Choosing a theme, deciding what to do with the funds).
                    </div>
                </div>
                <div className={styles["roadmap_block"]}>
                    <img className={styles["roadmap_logo-off"]} alt={"Fundamint Logo Closed"} src={"images/fundamint-logo.svg"} />
                    <div className={styles["roadmap_title"]}>
                        New series
                    </div>
                    <div className={styles["roadmap_description"]}>
                        Design and mint new series.
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Roadmap;
