import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from "react-i18next";
import AwsomeSliderFdm from "../awsome-slider";
import styles from "./index.module.scss";

const AboutKites = observer(() => {
    const { t } = useTranslation();

    return (
        <div id="about-kites" className={styles["container"]}>
            <div data-aos="zoom-in" className={styles["about-kites"]}>
                <h2>
                    {t("aboutKites.title")}
                </h2>
                <div className={styles["about-kites_content"]}>
                    <div className={styles["about-kites_description"]}>
                        <span>{t("aboutKites.description1")}</span>
                        <span>{t("aboutKites.description2")}</span>
                        <span>{t("aboutKites.description3")}</span>
                    </div>
                    <div className={styles["about-kites_slider"]}>
                        <AwsomeSliderFdm />
                    </div>
                </div>
            </div>
        </div>
    )
});

export default AboutKites;
