import { observer } from "mobx-react";
import React from 'react';
import styles from "./index.module.scss";

const Partners = observer(() => {

    return (

        <div id="partners" data-aos="zoom-in" className={styles["container"]}>
            <h2>
                Partners
            </h2>
            <div className={styles["partners_container"]}>
                <a className={styles["partners_image-container"]} href="https://www.polygon.com/" target="_blank">
                    <img className={styles["partners_image"]} src={"images/polygon-logo.svg"} alt="Polygon Logo" />
                </a>
                <a className={styles["partners_image-container"]} href="https://www.polydoge.com/" target="_blank">
                    <img className={styles["partners_image"]} src={"images/polydoge-logo.svg"} alt="PolyDoge Logo" />
                </a>
                <a className={styles["partners_image-container"]} href="https://ferrum.network/" target="_blank">
                    <img className={styles["partners_image"]} src={"images/ferrum-network-logo.svg"} alt="Ferrum Network Logo" />
                </a>
                <a className={styles["partners_image-container"]} href="https://www.nfthub.xyz/" target="_blank">
                    <img className={styles["partners_image"]} src={"images/nfthub-logo.png"} alt="Nfthub Logo" />
                </a>
            </div>
        </div>
    )
});

export default Partners;
