export const TRANSLATIONS_EN = {
    navbar: {
        home: "Home",
        mission: "Mission",
        currenCause: "Current Cause Collection",
        fdm: "Buy FDM NFT",
        about: "About us",
        roadmap: "Roadmap",
        team: "Team",
        partners: "Partners",
        proposal: "Proposal",
        menu: "Menu"
    },
    header: {
        title1: "Minting NFT Collectible Art to",
        title2: "heal the world.",
        sold: "NFTs sold",
        funded: "Funded to heal the World",
        collection: "Current FDM Collection:",
        kites: "Kites of Freedom",
        about: "About project",
        buy: "Buy NFT"
    },
    welcome: {
        title: "Welcome to the Fundamint Family",
        description: "When you buy a FDM NFT, you’re not simply buying a provably-rare piece of art. You are gaining access to a movement whose benefits and offerings will increase over time. Your FDM NFT is a symbol of creating a better world.",
        help: "Every NFT helps someone in need",
        ownership: "Ownership and commercial usage rights given to the consumer over their NFT",
        contribute: "You can make contributions to the creative process of the next collections",
        community: "Determine as a community where future funds are directed",
        value: "Increase value through our roadmap while we heal the world together"
    },
    aboutFdm: {
        title: "What is Fundamint",
        description: "Fundamint is an NFT DAO collaboration between different prominent individuals in the crypto and social impact space to create an NFT series which aims to do several things:",
        list1: "Create high quality NFTs with randomized traits + images using Chainlink's VRF technology",
        list2: "Take a strong humanitarian stance by donating part of the money to various causes. The first series will donate to the struggling people of Afghanistan who have been affected by bigger geopolitical events of which they can't control.",
        list3: "Reward users with a lottery based system providing lucky winners parts of the proceeds"
    },
    aboutKites: {
        title: "Kites of Freedom",
        description1: "Before the Taliban came to power there was one particular activity that was near and dear to the hearts of the Afghani people. That was Kite Flying. It was a touchstone of every Afghani soul. In 1996 as soon as the Taliban took power, they banned kite flying. This was a devastating blow to the bedrock of Afghani culture. Now everything was under the strict Sharia Law. Even dancing was prohibited.",
        description2: "Now with the take over of Afghanistan. So many thoughts are turned to the people. What will become of them? What will happen now that the Taliban has taken over. How can we help?",
        description3: "This is the cause that the team rallied around to kick off this initiative. We have watched the headlines and have been sick over the tragedies taking place. We are currently engaged in several talks with NPOs and foundations that are helping the Afghani people in this great time of need. Please send us a proposal of which organizations you feel we should fund. We are looking for a partner that can be fully vetted and is impactful.",
    },
    purchaseNfts: {
        title: "Purchase Kites of Freedom",
        sold: "SOLD"
    },
    rangeSlider: {
        title: "NFTs to purchase",
        total: "Total : "
    },
    team:
        [
            {
                image: "images/peter-profile.jpg",
                name: "Peter",
                quote: "Who knew that pressing keys in the right order, could change the world.",
                description: "Ever since I was a little kid I have been obsessed with the idea of using software to solve practical problems. I strongly believe in the power of programming to transform and improve the lives of people around the world."
            },
            {
                image: "images/bart-profile.jpg",
                name: "Bart Baker",
                quote: "The way to get started is to quit talking and begin doing.", description: "Bart Baker is a weird boy. He has 40 million followers worldwide. Nobody knows why. His passion for crypto and helping others is what led him to Fundamint."
            },
            {
                image: "images/devvie-profile.jpg",
                name: "Devvie",
                quote: "Blockchain technology to help others is going to transform the concept of giving as we know it today.",
                description: "Using blockchain technology to help is what brought my interest towards helping out with Fundamint. Being able to use my skills and the latest NFT interest together the possibility to help others is something that really resonates with me."
            },
            {
                image: "images/hato-profile.jpg",
                name: "Hato Mato",
                quote: "Everything You Imagine is real",
                description: "After 14 years of building brands, designing product lines and games for projects focused primarily on generating profits for only themselves, I am happy that now i can use my experience to build a legacy which makes the world better place thanks to the unique connection of art and technology. How exciting is that?"
            },
            {
                image: "images/pat-profile.jpg",
                name: "Pat",
                quote: "It ain't gonna sell itself",
                description: "I came from a struggling environment. I made it academically with hard work and dedication. I made it even harder by investing in early crypto. I made it through the networking with connection to a multitude of projects by founding PolyDoge. Now I'd like to gift back and share that success. Let's us make it happen."
            },
            {
                image: "images/jordan-profile.jpg",
                name: "Jordan Charters",
                quote: "No one is useless in this world who lightens the burdens of another",
                description: "I have been blessed to run several companies, serve in the US Marines, build significant communities to empower others and now create the new world through blockchain technology. What drew me to the Crypto Industry more than anything, is the potential to lift others. Fundamint is a full circle of all my passions put into one."
            }
        ]

};
