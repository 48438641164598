import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const AboutFdm = observer(() => {
    const { t } = useTranslation();

    return (
        <div id="about" className={styles["container"]}>
            <div data-aos="zoom-in" className={styles["about-fdm"]}>
                <h2>
                    {t("aboutFdm.title")}
                </h2>
                <div className={styles["about-fdm_content"]}>
                    <div className={styles["about-fdm_description"]}>
                        {t("aboutFdm.description")}
                        <div className={styles["about-fdm_wrapper"]}>
                            <div className={styles["about-fdm_donate"]}>
                                <img className={styles["about-fdm_child"]} src={"images/afghan-child.jpg"} alt="Afghan child" />
                            </div>
                            <div className={styles["about-fdm_list"]}>
                                <ol>
                                    <li>
                                        {t("aboutFdm.list1")}
                                    </li>
                                    <li>
                                        {t("aboutFdm.list2")}
                                    </li>
                                    <li>
                                        {t("aboutFdm.list3")}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default AboutFdm;
