import { observer } from "mobx-react";
import React from 'react';
import styles from "./index.module.scss";

const Footer = observer(() => {

  return (
    <>
      <div className={styles["footer-container"]}>
        © 2021 FundaMint.io | All rights reserved.
      </div>
    </>
  )
});

export default Footer;
