import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const WelcomeToFdm = observer(() => {
    const { t } = useTranslation();

    return (
        <div className={styles["container"]}>
            <div data-aos="zoom-in" className={styles["welcome-to-fdm"]}>
                <h2>
                    {t("welcome.title")}
                </h2>
                <span className={styles["welcome-to-fdm_description"]}>
                    {t("welcome.description")}
                </span>
                <div className={styles["fdm-icons_container"]}>
                    <div className={styles["fdm-icons_icon-block"]}>
                        <img className={styles["fdm-icons_icon"]} src={"images/help.svg"} alt="Fundamint Charity" />
                        <span className={styles["fdm-icons_text"]}>
                            {t("welcome.help")}
                        </span>
                    </div>
                    <div className={styles["fdm-icons_icon-block"]}>
                        <img className={styles["fdm-icons_icon"]} src={"images/donate.svg"} alt="Fundamint Charity" />
                        <span className={styles["fdm-icons_text"]}>
                            {t("welcome.ownership")}
                        </span>
                    </div>
                    <div className={styles["fdm-icons_icon-block"]}>
                        <img className={styles["fdm-icons_icon"]} src={"images/networking.svg"} alt="Fundamint Charity" />
                        <span className={styles["fdm-icons_text"]}>
                            {t("welcome.contribute")}
                        </span>
                    </div>
                    <div className={styles["fdm-icons_icon-block"]}>
                        <img className={styles["fdm-icons_icon"]} src={"images/teamwork.svg"} alt="Fundamint Charity" />
                        <span className={styles["fdm-icons_text"]}>
                            {t("welcome.community")}
                        </span>
                    </div>
                    <div className={styles["fdm-icons_icon-block"]}>
                        <img className={styles["fdm-icons_icon"]} src={"images/value.svg"} alt="Fundamint Charity" />
                        <span className={styles["fdm-icons_text"]}>
                            {t("welcome.value")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default WelcomeToFdm;
