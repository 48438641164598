import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import firebase from "../../firebase";
import styles from "./index.module.scss";

const Proposals = observer(() => {
    /*const [proposals, setProposals] = useState([]);
    let proposalsData = [];
    useEffect(() => {
        firebase.
            firestore()
            .collection('proposals')
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach((response) => {
                    let proposal = {};
                    proposal.title = response.data().title;
                    proposal.name = response.data().name;
                    proposal.message = response.data().message;
                    proposal.email = response.data().email;
                    proposalsData.push(proposal);
                })
            });
    }, [proposalsData]);

    console.log(proposals)

    return (
        <div className={styles["container"]}>
            {proposalsData.map(proposal => {
                return proposal.title;
            })}
        </div>
    )*/
});

export default Proposals;